<template>
<form
  id="app"
  v-on:submit.prevent="SalvarAnalise()"
  method="post"
>
  <b-card-code title="Formulario de Analise Tecnica">
    <b-row v-if="solidesenvolvimentoid > 0">
      <b-col
        md="6"
        xl="2"
        class="mb-1"
      >
        <b-form-group
          label="Nº Solicitação:"
        >
          <b-form-input
            id="numsoliproduto"
            placeholder=""
            v-model="numsoliproduto"
            readonly
          />
        </b-form-group>
      </b-col>

      <b-col
        md="6"
        xl="10"
        class="mb-1"
      >
        <b-form-group
          label="Produto:"
        >
          <b-form-input
            id="produtoInput"
            placeholder=""
            v-model="produto"
            required
            readonly
          />
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        md="6"
        xl="6"
        class="mb-1"
      >
        <b-form-group
          label="Produto Inovador:"
        >
        <div>
          <input name="prodinivador" type="radio" id="Sim" value="Sim" v-model="prodinivador" :disabled="PermiteSalvar ? false : '' " required> Sim
          <input name="prodinivador" type="radio" id="Nao" value="Não" v-model="prodinivador" :disabled="PermiteSalvar ? false : '' " required> Não
          <input name="prodinivador" type="radio" id="SemInformacao" value="Sem Informação" :disabled="PermiteSalvar ? false : '' " v-model="prodinivador" required> Sem Informação
        </div>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        md="6"
        xl="12"
        class="mb-1"
      >
        <b-form-group
          label="Justificativa:"
        >
          <b-form-textarea
            id="prodinovadorjustific"
            placeholder=""
            v-model="prodinovadorjustific"
            rows="3"
            :readonly="PermiteSalvar ? false : '' "
          />
        </b-form-group>
      </b-col>
    </b-row>

   <b-row>
      <b-col
        md="6"
        xl="6"
        class="mb-1"
      >
        <b-form-group
          label="Produto adequado à tecnologia da empresa:"
        >
        <div>
          <input name="prodadeqtecnologia" type="radio" id="Sim" value="Sim" v-model="prodadeqtecnologia" :disabled="PermiteSalvar ? false : '' " required> Sim
          <input name="prodadeqtecnologia" type="radio" id="Nao" value="Não" v-model="prodadeqtecnologia" :disabled="PermiteSalvar ? false : '' " required> Não
          <input name="prodadeqtecnologia" type="radio" id="SemInformacao" value="Sem Informação" v-model="prodadeqtecnologia" :disabled="PermiteSalvar ? false : '' " required> Sem Informação
        </div>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        md="6"
        xl="12"
        class="mb-1"
      >
        <b-form-group
          label="Justificativa:"
        >
          <b-form-textarea
            id="prodadeqtecnologiajustific"
            placeholder=""
            v-model="prodadeqtecnologiajustific"
            rows="3"
            :readonly="PermiteSalvar ? false : '' "
          />
        </b-form-group>
      </b-col>
    </b-row>

   <b-row>
      <b-col
        md="6"
        xl="6"
        class="mb-1"
      >
        <b-form-group
          label="Disponibilidade de matéria prima e ingredientes:"
        >
        <div>
          <input name="disponibmateriaprimaingri" type="radio" id="Sim" value="Sim" v-model="disponibmateriaprimaingri" :disabled="PermiteSalvar ? false : '' " required> Sim
          <input name="disponibmateriaprimaingri" type="radio" id="Nao" value="Não" v-model="disponibmateriaprimaingri" :disabled="PermiteSalvar ? false : '' " required> Não
          <input name="disponibmateriaprimaingri" type="radio" id="SemInformacao" value="Sem Informação" v-model="disponibmateriaprimaingri" :disabled="PermiteSalvar ? false : '' " required> Sem Informação
        </div>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        md="6"
        xl="12"
        class="mb-1"
      >
        <b-form-group
          label="Justificativa:"
        >
          <b-form-textarea
            id="disponibmateriaprimaingrijustific"
            placeholder=""
            v-model="disponibmateriaprimaingrijustific"
            rows="3"
            :readonly="PermiteSalvar ? false : '' "
          />
        </b-form-group>
      </b-col>
    </b-row>

   <b-row>
      <b-col
        md="6"
        xl="6"
        class="mb-1"
      >
        <b-form-group
          label="Fácil desenvolvimento de fornecedores:"
        >
        <div>
          <input name="facildesenvolvforncedor" type="radio" id="Sim" value="Sim" v-model="facildesenvolvforncedor" :disabled="PermiteSalvar ? false : '' " required> Sim
          <input name="facildesenvolvforncedor" type="radio" id="Nao" value="Não" v-model="facildesenvolvforncedor" :disabled="PermiteSalvar ? false : '' " required> Não
          <input name="facildesenvolvforncedor" type="radio" id="SemInformacao" value="Sem Informação" v-model="facildesenvolvforncedor" :disabled="PermiteSalvar ? false : '' " required> Sem Informação
        </div>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        md="6"
        xl="12"
        class="mb-1"
      >
        <b-form-group
          label="Justificativa:"
        >
          <b-form-textarea
            id="facildesenvolvforncedorjustific"
            placeholder=""
            v-model="facildesenvolvforncedorjustific"
            rows="3"
            :readonly="PermiteSalvar ? false : '' "
          />
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        md="6"
        xl="6"
        class="mb-1"
      >
        <b-form-group
          label="Equipamentos de linha atendem a demanda:"
        >
        <div>
          <input name="equipamentoslinhaatend" type="radio" id="Sim" value="Sim" v-model="equipamentoslinhaatend" :disabled="PermiteSalvar ? false : '' " required> Sim
          <input name="equipamentoslinhaatend" type="radio" id="Nao" value="Não" v-model="equipamentoslinhaatend" :disabled="PermiteSalvar ? false : '' " required> Não
          <input name="equipamentoslinhaatend" type="radio" id="SemInformacao" value="Sem Informação" v-model="equipamentoslinhaatend" :disabled="PermiteSalvar ? false : '' " required> Sem Informação
        </div>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        md="6"
        xl="12"
        class="mb-1"
      >
        <b-form-group
          label="Justificativa:"
        >
          <b-form-textarea
            id="equipamentoslinhaatendjustific"
            placeholder=""
            v-model="equipamentoslinhaatendjustific"
            rows="3"
            :readonly="PermiteSalvar ? false : '' "
          />
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        md="6"
        xl="6"
        class="mb-1"
      >
        <b-form-group
          label="Estrutura fabril (lay out) atende a demanda:"
        >
        <div>
          <input name="estruturafabrilatenddemanda" type="radio" id="Sim" value="Sim" v-model="estruturafabrilatenddemanda" :disabled="PermiteSalvar ? false : '' " required> Sim
          <input name="estruturafabrilatenddemanda" type="radio" id="Nao" value="Não" v-model="estruturafabrilatenddemanda" :disabled="PermiteSalvar ? false : '' " required> Não
          <input name="estruturafabrilatenddemanda" type="radio" id="SemInformacao" value="Sem Informação" v-model="estruturafabrilatenddemanda" :disabled="PermiteSalvar ? false : '' " required> Sem Informação
        </div>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        md="6"
        xl="12"
        class="mb-1"
      >
        <b-form-group
          label="Justificativa:"
        >
          <b-form-textarea
            id="estruturafabrilatenddemandajustific"
            placeholder=""
            v-model="estruturafabrilatenddemandajustific"
            rows="3"
            :readonly="PermiteSalvar ? false : '' "
          />
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        md="6"
        xl="6"
        class="mb-1"
      >
        <b-form-group
          label="Produto e processo não agregam novos perigos e riscos aos produtos em linha:"
        >
        <div>
          <input name="prodprocsnaoagregamriscosprodlinha" type="radio" id="Sim" value="Sim" v-model="prodprocsnaoagregamriscosprodlinha" :disabled="PermiteSalvar ? false : '' " required> Sim
          <input name="prodprocsnaoagregamriscosprodlinha" type="radio" id="Nao" value="Não" v-model="prodprocsnaoagregamriscosprodlinha" :disabled="PermiteSalvar ? false : '' " required> Não
          <input name="prodprocsnaoagregamriscosprodlinha" type="radio" id="SemInformacao" value="Sem Informação" v-model="prodprocsnaoagregamriscosprodlinha" :disabled="PermiteSalvar ? false : '' " required> Sem Informação
        </div>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        md="6"
        xl="12"
        class="mb-1"
      >
        <b-form-group
          label="Justificativa:"
        >
          <b-form-textarea
            id="prodprocsnaoagregamriscosprodlinhajustific"
            placeholder=""
            v-model="prodprocsnaoagregamriscosprodlinhajustific"
            rows="3"
            :readonly="PermiteSalvar ? false : '' "
          />
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        md="6"
        xl="6"
        class="mb-1"
      >
        <b-form-group
          label="Facilidade para atender requisitos legais ou de normas:"
        >
        <div>
          <input name="facilidatendreqlegais" type="radio" id="Sim" value="Sim" v-model="facilidatendreqlegais" :disabled="PermiteSalvar ? false : '' " required> Sim
          <input name="facilidatendreqlegais" type="radio" id="Nao" value="Não" v-model="facilidatendreqlegais" :disabled="PermiteSalvar ? false : '' " required> Não
          <input name="facilidatendreqlegais" type="radio" id="SemInformacao" value="Sem Informação" v-model="facilidatendreqlegais" :disabled="PermiteSalvar ? false : '' " required> Sem Informação
        </div>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        md="6"
        xl="12"
        class="mb-1"
      >
        <b-form-group
          label="Justificativa:"
        >
          <b-form-textarea
            id="facilidatendreqlegaisjustific"
            placeholder=""
            v-model="facilidatendreqlegaisjustific"
            rows="3"
            :readonly="PermiteSalvar ? false : '' "
          />
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        md="6"
        xl="6"
        class="mb-1"
      >
        <b-form-group
          label="Facilidade para atender requisitos específicos solicitados:"
        >
        <div>
          <input name="facilidatendreqespec" type="radio" id="Sim" value="Sim" v-model="facilidatendreqespec" :disabled="PermiteSalvar ? false : '' " required> Sim
          <input name="facilidatendreqespec" type="radio" id="Nao" value="Não" v-model="facilidatendreqespec" :disabled="PermiteSalvar ? false : '' " required> Não
          <input name="facilidatendreqespec" type="radio" id="SemInformacao" value="Sem Informação" v-model="facilidatendreqespec" :disabled="PermiteSalvar ? false : '' " required> Sem Informação
        </div>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        md="6"
        xl="12"
        class="mb-1"
      >
        <b-form-group
          label="Justificativa:"
        >
          <b-form-textarea
            id="facilidatendreqespecjustific"
            placeholder=""
            v-model="facilidatendreqespecjustific"
            rows="3"
            :readonly="PermiteSalvar ? false : '' "
          />
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        md="6"
        xl="6"
        class="mb-1"
      >
        <b-form-group
          label="Volume adequado para a linha de produção estimada:"
        >
        <div>
          <input name="volumeadeqlinhaproducao" type="radio" id="Sim" value="Sim" v-model="volumeadeqlinhaproducao" :disabled="PermiteSalvar ? false : '' " required> Sim
          <input name="volumeadeqlinhaproducao" type="radio" id="Nao" value="Não" v-model="volumeadeqlinhaproducao" :disabled="PermiteSalvar ? false : '' " required> Não
          <input name="volumeadeqlinhaproducao" type="radio" id="SemInformacao" value="Sem Informação" v-model="volumeadeqlinhaproducao" :disabled="PermiteSalvar ? false : '' " required> Sem Informação
        </div>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        md="6"
        xl="12"
        class="mb-1"
      >
        <b-form-group
          label="Justificativa:"
        >
          <b-form-textarea
            id="volumeadeqlinhaproducaojustific"
            placeholder=""
            v-model="volumeadeqlinhaproducaojustific"
            rows="3"
            :readonly="PermiteSalvar ? false : '' "
          />
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        md="6"
        xl="6"
        class="mb-1"
      >
        <b-form-group
          label="Possibilidade de atendimento de prazos esperados:"
        >
        <div>
          <input name="possibilidatendprazo" type="radio" id="Sim" value="Sim" v-model="possibilidatendprazo" :disabled="PermiteSalvar ? false : '' " required> Sim
          <input name="possibilidatendprazo" type="radio" id="Nao" value="Não" v-model="possibilidatendprazo" :disabled="PermiteSalvar ? false : '' " required> Não
          <input name="possibilidatendprazo" type="radio" id="SemInformacao" value="Sem Informação" v-model="possibilidatendprazo" :disabled="PermiteSalvar ? false : '' " required> Sem Informação
        </div>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        md="6"
        xl="12"
        class="mb-1"
      >
        <b-form-group
          label="Justificativa:"
        >
          <b-form-textarea
            id="possibilidatendprazojustific"
            placeholder=""
            v-model="possibilidatendprazojustific"
            rows="3"
            :readonly="PermiteSalvar ? false : '' "
          />
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        md="6"
        xl="6"
        class="mb-1"
      >
        <b-form-group
          label="Possibilidade de atendimento de preço esperado:"
        >
        <div>
          <input name="possibilidatendpreco" type="radio" id="Sim" value="Sim" v-model="possibilidatendpreco" :disabled="PermiteSalvar ? false : '' " required> Sim
          <input name="possibilidatendpreco" type="radio" id="Nao" value="Não" v-model="possibilidatendpreco" :disabled="PermiteSalvar ? false : '' " required> Não
          <input name="possibilidatendpreco" type="radio" id="SemInformacao" value="Sem Informação" v-model="possibilidatendpreco" :disabled="PermiteSalvar ? false : '' " required> Sem Informação
        </div>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        md="6"
        xl="12"
        class="mb-1"
      >
        <b-form-group
          label="Justificativa:"
        >
          <b-form-textarea
            id="possibilidatendprecojustific"
            placeholder=""
            v-model="possibilidatendprecojustific"
            rows="3"
            :readonly="PermiteSalvar ? false : '' "
          />
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        md="6"
        xl="12"
        class="mb-1"
      >
        <b-form-group
          label="Análise Detalhada:"
        >
          <b-form-textarea
            id="analisedetalhada"
            placeholder=""
            v-model="analisedetalhada"
            rows="3"
            required
            :readonly="PermiteSalvar ? false : '' "
          />
        </b-form-group>
      </b-col>
    </b-row>
  </b-card-code>

  <b-card-code title="">
    <b-row>
      <b-col
        md="6"
        xl="12"
        class="mb-1"
      >
        <b-form-group
          label="Comentario Global:"
        >
          <b-form-textarea
            id="comentglobal"
            placeholder=""
            v-model="comentglobal"
            rows="3"
            :readonly="AcessoDiretoria ? false : '' "
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        md="6"
        xl="8"
        class="mb-1"
      >

  <div class="DivBotaoLadoLado" >
    <b-button
      variant="danger"
      type="button"
      v-if="AcessoDiretoria"
      v-on:click="Reprovar()"
    >
      <span>REPROVAR</span>
    </b-button>
  </div>

  <div class="DivBotaoLadoLado" >
    <b-button
      variant="success"
      type="button"
      v-if="AcessoDiretoria"
      v-on:click="Aprovar()"
    >
      <span>APROVAR</span>
    </b-button>
  </div>

  <div class="DivBotaoLadoLado" >
    <b-button
      variant="warning"
      type="button"
      v-if="AcessoDiretoria"
      v-on:click="AprovarRestricao()"
    >
      <span>APROVAR COM RESTRIÇÃO</span>
    </b-button>
  </div>

    </b-col>
    </b-row>
  </b-card-code>

  <b-card-code title="">
  <b-row>
    <b-col
        md="6"
        xl="12"
        class="mb-1"
      >

    <div class="DivBotaoLadoLado" >
       <b-button
      variant="danger"
      type="button"
      @click="$router.push('../DevProduto')">
      <span>RETORNAR</span>
      </b-button>
    </div>

    <div class="DivBotaoLadoLado" >
     <b-button
      variant="success"
      type="submit"
      v-if="PermiteSalvar">
      <span>SALVAR</span>
    </b-button>
    </div>

   <div class="DivBotaoLadoLado" >
     <b-button
       variant="warning"
       type="button"
       v-if="AcessoPeD"
       v-on:click="SalvarAnaliseEnviarDiretoria()"
     >
       <span>SALVAR/ENVIAR DIRETORIA</span>
     </b-button>
   </div>
    </b-col>
    </b-row>
  </b-card-code>
</form>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {
  BFormInput, BRow, BCol, BFormGroup, BFormTextarea, BButton,
} from 'bootstrap-vue'
import apiPortalSky from '@/services/apiPortalSky'
import AlertaMsg from '@/customPortal/AlertaMsg.vue'
import ConexaoAPI from '@/customPortal/ConexaoAPI.vue'

export default {
  components: {
    BCardCode,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BFormTextarea,
    BButton,
  },
  data() {
    return {
      notification: {
      },
      PermiteSalvar: false,
      AcessoDiretoria: false,
      AcessoPeD: false,
      Desenvolvimento: {
      },
      solidesenvolvimentoid: 0,
      numsoliproduto: '',
      produto: '',
      prodinivador: '',
      prodinovadorjustific: '',
      prodadeqtecnologia: '',
      prodadeqtecnologiajustific: '',
      disponibmateriaprimaingri: '',
      disponibmateriaprimaingrijustific: '',
      facildesenvolvforncedor: '',
      facildesenvolvforncedorjustific: '',
      equipamentoslinhaatend: '',
      equipamentoslinhaatendjustific: '',
      estruturafabrilatenddemanda: '',
      estruturafabrilatenddemandajustific: '',
      prodprocsnaoagregamriscosprodlinha: '',
      prodprocsnaoagregamriscosprodlinhajustific: '',
      facilidatendreqlegais: '',
      facilidatendreqlegaisjustific: '',
      facilidatendreqespec: '',
      facilidatendreqespecjustific: '',
      volumeadeqlinhaproducao: '',
      volumeadeqlinhaproducaojustific: '',
      possibilidatendprazo: '',
      possibilidatendprazojustific: '',
      possibilidatendpreco: '',
      possibilidatendprecojustific: '',
      analisedetalhada: '',
      comentglobal: '',
      statusworkflowid: 0,
      acessoetapa: 0,
    }
  },
  methods: {
    async SalvarAnaliseEnviarDiretoria() {
      this.notification = { message: '', alert_type: 'error' }
      const usuario = this.$session.get('usuario')
      const URLpost = `/SoliDesenvolvimento/SoliDesenvolvimentoAnalise?CODUSU=${usuario.codusu}`
      this.CarregaDadosNoObjeto() // Carrega os Dados no Objeto para enviar no Parametro
      const ParametroJson = JSON.parse(JSON.stringify(this.Desenvolvimento))
      await apiPortalSky.post(URLpost, ParametroJson, {
        headers: {
          'Content-Type': 'application/json',
        },
      }).then(response => {
        if (response.status === 200) {
          this.notification.alert_type = 'success'
        } else {
          this.notification.alert_type = 'error'
          this.notification.message = 'Verifique os Dados Preenchidos e Tente novamente'
        }
      })
        .catch(error => {
          this.notification = ConexaoAPI.ValidaErro(error)
        })
      // Sucesso / Altera o Status para Pendente Diretoria
      if (this.notification.alert_type === 'success') {
        this.statusworkflowid = 6 // PENDENTE DIRETORIA
        await this.SoliDesenvolvimentoAlteraStatus()
        if (this.notification.alert_type === 'success') {
          this.notification.message = 'Enviado para Diretoria com Sucesso'
          this.$session.set('notification', this.notification)
          this.$router.push('../DevProduto')
        } else {
          AlertaMsg.FormataMsg(this.notification)
        }
      } else {
        AlertaMsg.FormataMsg(this.notification)
      }
    },
    async SalvarAnalise() {
      let MsgErro = ''
      const usuario = this.$session.get('usuario')
      const URLpost = `/SoliDesenvolvimento/SoliDesenvolvimentoAnalise?CODUSU=${usuario.codusu}`
      this.CarregaDadosNoObjeto() // Carrega os Dados no Objeto para enviar no Parametro
      const ParametroJson = JSON.parse(JSON.stringify(this.Desenvolvimento))
      await apiPortalSky.post(URLpost, ParametroJson, {
        headers: {
          'Content-Type': 'application/json',
        },
      }).then(response => {
        if (response.status === 200) {
          // this.Desenvolvimento = response.data
          MsgErro = `Formulario ${this.numsoliproduto} Alterado com Sucesso`
          this.notification = { message: MsgErro, alert_type: 'success' }
          this.$session.set('notification', this.notification)
          // Faz a navegação para a Tela de Lista
          this.$router.push('../DevProduto')
        }
      })
        .catch(error => {
          this.notification = ConexaoAPI.ValidaErro(error)
          AlertaMsg.FormataMsg(this.notification)
        })
    },
    async MostraFormularioSolicitacao($SOLIDESENVOLVIMENTOID) {
      const usuario = this.$session.get('usuario')
      const SOLIDESENVOLVIMENTOID = $SOLIDESENVOLVIMENTOID
      const ETAPA = 'FormularioAnalise'
      const URLpost = `/SoliDesenvolvimento/SoliDesenvolvimentoMostra?CODUSU=${usuario.codusu}&SOLIDESENVOLVIMENTOID=${SOLIDESENVOLVIMENTOID}&ETAPA=${ETAPA}`
      await apiPortalSky.post(URLpost, SOLIDESENVOLVIMENTOID).then(response => {
        if (response.status === 200) {
          this.Desenvolvimento = response.data
          this.DescarregaDadosDoObjeto()
          this.ValidaAcesso()
        }
      }).catch(error => {
        this.notification = ConexaoAPI.ValidaErro(error)
        AlertaMsg.FormataMsg(this.notification)
      })
    },
    async SoliDesenvolvimentoAlteraStatus() {
      this.notification = { message: '', alert_type: 'error' }
      const usuario = this.$session.get('usuario')
      const URLpost = `/SoliDesenvolvimento/SoliDesenvolvimentoAlteraStatus?CODUSU=${usuario.codusu}`
      this.CarregaDadosNoObjeto() // Carrega os Dados no Objeto para enviar no Parametro
      const ParametroJson = JSON.parse(JSON.stringify(this.Desenvolvimento))
      await apiPortalSky.post(URLpost, ParametroJson, {
        headers: {
          'Content-Type': 'application/json',
        },
      }).then(response => {
        if (response.status === 200) {
          this.notification = { message: '', alert_type: 'success' }
        } else {
          this.notification = { message: 'Operação não Realizada', alert_type: 'error' }
        }
      })
        .catch(error => {
          this.notification = ConexaoAPI.ValidaErro(error)
        })
    },
    async Reprovar() {
      this.statusworkflowid = 1 // PENDENTE SOLICITANTE
      await this.SoliDesenvolvimentoAlteraStatus()
      if (this.notification.alert_type === 'success') {
        this.notification.message = 'Solicitação de Desenvolvimento enviada para o Solicitante'
        this.$session.set('notification', this.notification)
        this.$router.push('../DevProduto')
      } else {
        AlertaMsg.FormataMsg(this.notification)
      }
    },
    async Aprovar() {
      this.statusworkflowid = 3 // ETP-01 - PLANEJAMENTO
      await this.SoliDesenvolvimentoAlteraStatus()
      if (this.notification.alert_type === 'success') {
        this.notification.message = 'Enviado para Desenvolvimento com Sucesso'
        this.$session.set('notification', this.notification)
        this.$router.push('../DevProduto')
      } else {
        AlertaMsg.FormataMsg(this.notification)
      }
    },
    async AprovarRestricao() {
      this.statusworkflowid = 1 // PENDENTE SOLICITANTE
      await this.SoliDesenvolvimentoAlteraStatus()
      if (this.notification.alert_type === 'success') {
        this.notification.message = 'Enviado para o Solicitante com Sucesso'
        this.$session.set('notification', this.notification)
        this.$router.push('../DevProduto')
      } else {
        AlertaMsg.FormataMsg(this.notification)
      }
    },
    ValidaAcesso() {
      switch (this.statusworkflowid) {
        case 1: // Pendente Solicitante
          this.PermiteSalvar = false
          break
        case 6: // Pendente Diretoria
          this.PermiteSalvar = false
          this.AcessoPeD = false
          if (this.acessoetapa === 1) {
            this.AcessoDiretoria = true
          }
          break
        case 2: // Pendente P&D
          if (this.acessoetapa === 1) {
            this.PermiteSalvar = true
            this.AcessoPeD = true
          }
          break
        default:
          this.PermiteSalvar = false
          break
      }
    },
    CarregaDadosNoObjeto() {
      this.Desenvolvimento.solidesenvolvimentoid = this.solidesenvolvimentoid
      this.Desenvolvimento.numsoliproduto = this.numsoliproduto
      this.Desenvolvimento.prodinivador = this.prodinivador
      this.Desenvolvimento.prodinovadorjustific = this.prodinovadorjustific
      this.Desenvolvimento.prodadeqtecnologia = this.prodadeqtecnologia
      this.Desenvolvimento.prodadeqtecnologiajustific = this.prodadeqtecnologiajustific
      this.Desenvolvimento.disponibmateriaprimaingri = this.disponibmateriaprimaingri
      this.Desenvolvimento.disponibmateriaprimaingrijustific = this.disponibmateriaprimaingrijustific
      this.Desenvolvimento.facildesenvolvforncedor = this.facildesenvolvforncedor
      this.Desenvolvimento.facildesenvolvforncedorjustific = this.facildesenvolvforncedorjustific
      this.Desenvolvimento.equipamentoslinhaatend = this.equipamentoslinhaatend
      this.Desenvolvimento.equipamentoslinhaatendjustific = this.equipamentoslinhaatendjustific
      this.Desenvolvimento.estruturafabrilatenddemanda = this.estruturafabrilatenddemanda
      this.Desenvolvimento.estruturafabrilatenddemandajustific = this.estruturafabrilatenddemandajustific
      this.Desenvolvimento.prodprocsnaoagregamriscosprodlinha = this.prodprocsnaoagregamriscosprodlinha
      this.Desenvolvimento.prodprocsnaoagregamriscosprodlinhajustific = this.prodprocsnaoagregamriscosprodlinhajustific
      this.Desenvolvimento.facilidatendreqlegais = this.facilidatendreqlegais
      this.Desenvolvimento.facilidatendreqlegaisjustific = this.facilidatendreqlegaisjustific
      this.Desenvolvimento.facilidatendreqespec = this.facilidatendreqespec
      this.Desenvolvimento.facilidatendreqespecjustific = this.facilidatendreqespecjustific
      this.Desenvolvimento.volumeadeqlinhaproducao = this.volumeadeqlinhaproducao
      this.Desenvolvimento.volumeadeqlinhaproducaojustific = this.volumeadeqlinhaproducaojustific
      this.Desenvolvimento.possibilidatendprazo = this.possibilidatendprazo
      this.Desenvolvimento.possibilidatendprazojustific = this.possibilidatendprazojustific
      this.Desenvolvimento.possibilidatendpreco = this.possibilidatendpreco
      this.Desenvolvimento.possibilidatendprecojustific = this.possibilidatendprecojustific
      this.Desenvolvimento.analisedetalhada = this.analisedetalhada
      this.Desenvolvimento.comentglobal = this.comentglobal
      this.Desenvolvimento.statusworkflowid = this.statusworkflowid
      this.Desenvolvimento.acessoetapa = this.acessoetapa
    },
    DescarregaDadosDoObjeto() {
      this.solidesenvolvimentoid = this.Desenvolvimento.solidesenvolvimentoid
      this.numsoliproduto = this.Desenvolvimento.numsoliproduto
      this.produto = this.Desenvolvimento.produto
      this.prodinivador = this.Desenvolvimento.prodinivador
      this.prodinovadorjustific = this.Desenvolvimento.prodinovadorjustific
      this.prodadeqtecnologia = this.Desenvolvimento.prodadeqtecnologia
      this.prodadeqtecnologiajustific = this.Desenvolvimento.prodadeqtecnologiajustific
      this.disponibmateriaprimaingri = this.Desenvolvimento.disponibmateriaprimaingri
      this.disponibmateriaprimaingrijustific = this.Desenvolvimento.disponibmateriaprimaingrijustific
      this.facildesenvolvforncedor = this.Desenvolvimento.facildesenvolvforncedor
      this.facildesenvolvforncedorjustific = this.Desenvolvimento.facildesenvolvforncedorjustific
      this.equipamentoslinhaatend = this.Desenvolvimento.equipamentoslinhaatend
      this.equipamentoslinhaatendjustific = this.Desenvolvimento.equipamentoslinhaatendjustific
      this.estruturafabrilatenddemanda = this.Desenvolvimento.estruturafabrilatenddemanda
      this.estruturafabrilatenddemandajustific = this.Desenvolvimento.estruturafabrilatenddemandajustific
      this.prodprocsnaoagregamriscosprodlinha = this.Desenvolvimento.prodprocsnaoagregamriscosprodlinha
      this.prodprocsnaoagregamriscosprodlinhajustific = this.Desenvolvimento.prodprocsnaoagregamriscosprodlinhajustific
      this.facilidatendreqlegais = this.Desenvolvimento.facilidatendreqlegais
      this.facilidatendreqlegaisjustific = this.Desenvolvimento.facilidatendreqlegaisjustific
      this.facilidatendreqespec = this.Desenvolvimento.facilidatendreqespec
      this.facilidatendreqespecjustific = this.Desenvolvimento.facilidatendreqespecjustific
      this.volumeadeqlinhaproducao = this.Desenvolvimento.volumeadeqlinhaproducao
      this.volumeadeqlinhaproducaojustific = this.Desenvolvimento.volumeadeqlinhaproducaojustific
      this.possibilidatendprazo = this.Desenvolvimento.possibilidatendprazo
      this.possibilidatendprazojustific = this.Desenvolvimento.possibilidatendprazojustific
      this.possibilidatendpreco = this.Desenvolvimento.possibilidatendpreco
      this.possibilidatendprecojustific = this.Desenvolvimento.possibilidatendprecojustific
      this.analisedetalhada = this.Desenvolvimento.analisedetalhada
      this.comentglobal = this.Desenvolvimento.comentglobal
      this.statusworkflowid = this.Desenvolvimento.statusworkflowid
      this.acessoetapa = this.Desenvolvimento.acessoetapa
    },
  },
  mounted() {
    if (this.$route.params.solidesenvolvimentoid != null) {
      this.MostraFormularioSolicitacao(this.$route.params.solidesenvolvimentoid)
    } else {
      this.PermiteSalvar = true
    }
  },
}
</script>
